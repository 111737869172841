import { Md5 } from 'ts-md5';
import { formatProductString, getImpressionParentElement, getElementText } from './utils.js';

export const createImpression = (targetElement: HTMLElement, productString: string, path: EventTarget[] | string) => {
  const hashedLinkValue = Md5.hashStr(targetElement.id || getElementText(targetElement));
  const isPageImpression = typeof path === 'string';
  productString = formatProductString({
    impressionVariable: 'eVar110',
    impressionEventType: isPageImpression ? 'displayed' : 'clicked',
    impressionType: 'pagefeature',
    slots: [
      typeof path === 'string' ? path : getImpressionParentElement(path, true),
      '',
      '',
      getElementText(targetElement),
      hashedLinkValue,
      '',
    ],
  });
  return productString;
};
