import '@webcomponents/scoped-custom-element-registry';
import 'element-internals-polyfill';
import { registerIconSet } from '@cigna/dcws_leaf-web-components/utilities/icon-library.js';
import { scrollToAnchor } from '@cigna/dcws_leaf-web-components/utilities/anchor.js';
import { GenericContextProvider as ContextProvider } from '@cigna/dcws_leaf-web-components/utilities/context/generic-provider.js';
import { elementUpdated } from '@cigna/dcws_leaf-web-components/utilities/element-updated.js';
import '@cigna/dcws_leaf-web-components/utilities/translations/es.js';
import { ContextRoot } from '@lit/context';
import pMemoize from 'p-memoize';
import pWaitFor from 'p-wait-for';
import { i18n } from 'i18next';
import * as contexts from './apps/contexts.js';
import { initialize as initURLSelects } from './apps/url-select-form/service.js';
import { initialize as initHideShowSelect } from './apps/hide-show-select-form/service.js';
import { initialize as initContactUsState } from './apps/contact-us-state/index.js';
import { CampaignsService } from './apps/campaigns/service.js';
import { personalizeContent } from './apps/personalized-content/service.js';
import { loadComponents } from './components.js';
import { environment } from './environments/environment.js';
import { fixExternalLinks, updateLink } from './utils/fix-links.js';
import { handleEloquaForms } from './utils/forms.js';
import { setInlineClasses } from './utils/inline-class.js';
import { queryShadowRoot } from './utils/query-shadow-root.js';
import { updateDynamicLinks } from './apps/campaigns/link-service.js';
import { setupClickTracking, createImpression } from './analytics/index.js';

// Bootstrap the application

// Register icons sets
registerIconSet('logos', (name: string) => `/static/svgs/logos/${name}-logo.svg`);
registerIconSet('chc', (name: string) => `/static/svgs/chc/${name}.svg`);
registerIconSet('social', (name: string) => `/static/svgs/chc/social/${name}.svg`);

// add personalization early
personalizeContent(document);

// load/register components and apps
loadComponents(environment.logger);

// styling adjustments for Teamsite added markup
setInlineClasses();

fixExternalLinks();
handleEloquaForms(environment.flags.enableEloquaTestEmails, environment.logger);
initURLSelects();
initHideShowSelect();

// Provide config, functions, and services down to components
const contextProvider = new ContextProvider(document.body);
new ContextRoot().attach(document.body);

contextProvider.provide(contexts.endpointsContext, environment.endpoints);
contextProvider.provide(
  contexts.i18nContext,
  pMemoize(() => import('./utils/i18n.js').then((module) => module.initI18n(environment.i18n))) as () => Promise<i18n>,
);

const campaignsService = new CampaignsService(environment.endpoints.campaigns, environment.logger);
campaignsService.getCampaignsInfo().then((campaignInfo) => {
  try {
    contextProvider.provide(contexts.dynamicPhoneNumberContext, campaignInfo['campaignPhone-cipublic']);
    contextProvider.provide(contexts.dynamicLinksContext, campaignInfo);

    // TODO: Think about reworking this
    queryShadowRoot(document.body).forEach((link) => {
      const href = link.getAttribute('href');
      if (href) {
        const newLinkHref = updateDynamicLinks(href, campaignInfo);
        if (newLinkHref && newLinkHref.length) {
          link.setAttribute('href', newLinkHref);
        }
        updateLink(link as HTMLAnchorElement);
      }
    });
  } catch (error) {
    environment.logger?.error(error);
  }
});

// Setup analytics
try {
  // tell apps that Adobe is loaded/ready to listen for events, remove 3000 to wait indefinitely
  await pWaitFor(() => Object.prototype.hasOwnProperty.call(window, '_satellite'), { timeout: 3000 });
  environment.logger?.info('analytics ready', window._satellite);
  contextProvider.provide(contexts.analyticsReadyContext, true);
} catch (err) {
  environment.logger?.warn('Error waiting for Adobe Launch to initialize', err as Error);
}
setupClickTracking(createImpression, environment.logger);

window.addEventListener('load', async (evt: Event) => {
  evt.preventDefault();
  if (document.querySelector('chc-sticky-banner, chc-sticky-anchor-links')) {
    document.querySelectorAll('[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', (event: Event) => {
        event.preventDefault();
        const anchorHash = anchor.getAttribute('href');
        scrollToAnchor(anchorHash?.replace('#', '') || '');
        location.hash = anchorHash || '';
      });
    });
  }

  const stickyBanner = document.querySelector('chc-sticky-banner');
  if (stickyBanner) {
    try {
      await pWaitFor(() => elementUpdated(stickyBanner), { timeout: 3000 });
      scrollToAnchor();
    } catch (err) {
      environment.logger?.warn('Error waiting for chc-sticky-banner updateComplete lifecycle event', err as Error);
    }
  }

  initContactUsState();
});
