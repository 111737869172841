import { emit } from '@cigna/dcws_leaf-web-components/utilities/events.js';

export const handleEloquaForms = (enableEloquaTestEmails: boolean, logger?: Console) => {
  const forms: NodeListOf<HTMLFormElement> = document.querySelectorAll('[action*="eloqua"]');
  forms.forEach((form) => {
    let token: string | undefined;

    if (!form.hasAttribute('data-no-form-token')) {
      const siteId =
        form.querySelector('[name="elqSiteId"]')?.getAttribute('value') ||
        form.action.split(/https?:|\/\/|\./).filter((str) => str)[0];

      // Get token on page load to avoid `timestamptoofast` spam identifier in Eloqua
      fetch(`https://s${siteId}.t.eloqua.com/e/formsubmittoken?elqSiteID=${siteId}`).then((response) =>
        response.text().then((data) => (token = data)),
      );
    }

    form.addEventListener('submit', async (event) => {
      event?.preventDefault();
      const data = new FormData(form);
      const spinner = form.parentNode?.querySelector('leaf-spinner');
      form.setAttribute('hidden', 'true');
      spinner?.removeAttribute('hidden');
      try {
        // set dynamic fields - will overwrite what is in the form already
        data.set('FormLocation', location.pathname);
        data.set('PageName', document.querySelector('h1')?.textContent ?? document.title);
        if (token) {
          data.set('elqFormSubmissionToken', token);
        }
        if (enableEloquaTestEmails) {
          data.set('TestEnvironment', 'true');
        }

        const response = await fetch(form.action, { method: 'post', body: data });
        if (response.status === 200) {
          const successAlert = form.parentNode?.querySelector('leaf-alert[type="success"]');
          spinner?.setAttribute('hidden', 'true');
          successAlert?.setAttribute('open', 'true');
          emit(
            'analytics-form-submit',
            {
              detail: {
                event,
                targetAction: form.action || '',
                targetForm: form,
                targetName: form.name || '',
              },
            },
            document.body,
          );
        } else {
          throw new Error(`Fetch response: ${response}`);
        }
      } catch (error) {
        logger?.warn(`Error submitting Eloqua form: ${error}`);
        const errorAlert = form.parentNode?.querySelector('leaf-alert[type="error"]');
        spinner?.setAttribute('hidden', 'true');
        errorAlert?.setAttribute('open', 'true');
      }
    });
  });
};
