// a mixin sets up our common pattern for loading app data in i18next
// that watches for config changes
// TODO: is this really needed?
import { html, LitElement, TemplateResult } from 'lit';
import { ContextConsumer, createContext } from '@lit/context';
import { I18nController } from './i18n-controller.js';
import type { i18n } from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T = Record<string, unknown>> = new (...args: any[]) => T;

export const i18nContext = createContext<() => Promise<i18n>>('i18n');

export declare class I18nMixinInterface {
  protected i18n?: I18nController;
  protected ns: string | string[];
  protected renderLoadingOrError(): TemplateResult<1> | undefined;
  onNamespacesLoaded(): void;
}

export const I18nMixin = <T extends Constructor<LitElement>>(superClass: T) => {
  class I18nMixinClass extends superClass {
    protected ns: string | string[] = '_namespace-not-provided_';
    protected i18n?: I18nController;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any[]) {
      super(...args);
      new ContextConsumer(this, {
        context: i18nContext,
        callback: (value) => {
          value().then((instance) => {
            this.i18n = new I18nController(this, instance, this.ns);
          });
        },
      });
    }

    // override to run code when namespaces are loaded
    onNamespacesLoaded() {}

    // @ts-ignore may return undefined
    protected renderLoadingOrError() {
      if (!this.i18n || this.i18n.loading) {
        return html`<slot name="loading"></slot>`;
      }
      if (this.i18n?.error) {
        return html`<slot name="error"></slot>`;
      }
    }
  }

  return I18nMixinClass as unknown as Constructor<I18nMixinInterface> & T;
};
