import Cookies from 'js-cookie';

declare const window: {
  mycignaMaintenanceMode?: boolean;
} & Window;

const VisitorType = {
  unspecified: 'unspecified',
  customer: 'authenticated-customer',
  employer: 'authenticated-employer',
  professional: 'authenticated-professional',
} as const;

type TVisitorType =
  | typeof VisitorType.unspecified
  | typeof VisitorType.customer
  | typeof VisitorType.employer
  | typeof VisitorType.professional;

const COOKIE_TO_VISITOR_TYPE: { [key: string]: TVisitorType } = {
  OEP_li: VisitorType.customer,
  'is-cfe-user': VisitorType.employer,
  'is-chcp-user': VisitorType.professional,
};

class Visitor {
  private _types?: TVisitorType[];

  private get types() {
    if (!this._types) {
      const visitortypes = Object.entries(COOKIE_TO_VISITOR_TYPE).reduce((matches, [key, value]) => {
        if (Cookies.get(key)) {
          matches.add(value);
        }
        return matches;
      }, new Set() as Set<TVisitorType>);

      if (!visitortypes.size) {
        visitortypes.add(VisitorType.unspecified);
      }

      this._types = [...visitortypes];
    }
    return this._types;
  }

  inAudience(audience: TVisitorType) {
    return this.types.includes(audience);
  }
}

const shouldShow = (element: HTMLElement, visitor: Visitor) => {
  // if element is tagged with data-maintenance-participant and mycigna maintenance
  // mode is on then don't show
  const maintenaceParticipant = element.dataset['maintenance-participant'];
  if (maintenaceParticipant && window.mycignaMaintenanceMode) {
    return false;
  }

  const audiences = element.dataset.audience?.split(',') as TVisitorType[];
  return audiences.some((audience) => visitor.inAudience(audience));
};

export const personalizeContent = (node: ParentNode) => {
  const visitor = new Visitor();
  const taggedComponents = node.querySelectorAll('[data-audience]');
  const componentsToShow = [...taggedComponents].filter((element) => shouldShow(element as HTMLElement, visitor));
  componentsToShow.forEach((element) => {
    element.setAttribute('data-show', 'true');
  });
  // TODO: remove unmatched elements from DOM?
};
