import { createContext } from '@lit/context';
import type { ICampaignInfo } from './campaigns/interfaces.js';
import type { EnvironmentConfig } from '../environments/interfaces.js';

export {
  analyticsReadyContext,
  dynamicPhoneNumberContext,
} from '@cigna/dcws_leaf-web-components/chc-components/phone-number/phone-number-context.js';
export { i18nContext } from '../utils/i18n-mixin.js';

export const dynamicLinksContext = createContext<ICampaignInfo>('campaignInfo');
export const endpointsContext = createContext<EnvironmentConfig['endpoints']>('endpoints');
