import { LeafSelect } from '@cigna/dcws_leaf-web-components/components/select/select.component.js';

export const addInitialSelectValue = (formElement: HTMLFormElement) => {
  const hashValue = window.location.hash.substring(1).split('=').pop();
  const selectElement = formElement.querySelector('leaf-select') as LeafSelect;
  if (hashValue) {
    selectElement.setAttribute('value', `contact-us-${hashValue}`);
    if (selectElement.value) {
      requestAnimationFrame(() => {
        formElement.requestSubmit();
      });
    }
  }
  formElement.addEventListener('submit', () => {
    window.location.hash = `!state=${selectElement.value?.slice(11, 13)}`;
  });
};

export const initialize = (selector = 'form.contact-us-state-form') => {
  document.querySelectorAll(selector).forEach((form) => {
    addInitialSelectValue(form as HTMLFormElement);
  });
};
