export const queryShadowRoot = (root: ShadowRoot | HTMLElement, maxDepth = 600, depth = 0): HTMLElement[] => {
  const isLink = ($elem: HTMLElement): boolean => $elem.hasAttribute('href');
  const matches: HTMLElement[] = [];

  if (depth >= maxDepth) {
    return matches;
  }

  const traverseSlot = ($slot: HTMLSlotElement) => {
    const assignedNodes = $slot.assignedNodes().filter((node) => node.nodeType === 1);

    if (assignedNodes.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const $slotParent = assignedNodes[0].parentElement!;
      return queryShadowRoot($slotParent, maxDepth, depth + 1);
    }

    return [];
  };

  const children = <HTMLElement[]>Array.from(root.children || []);
  if (root instanceof HTMLElement && root.tagName && children.length === 0) {
    children.push(root as HTMLElement);
  }

  for (const $child of children) {
    if (isLink($child)) {
      matches.push($child);
    }

    if ($child.shadowRoot != null) {
      matches.push(...queryShadowRoot($child.shadowRoot, maxDepth, depth + 1));
    } else if ($child.tagName === 'SLOT') {
      matches.push(...traverseSlot(<HTMLSlotElement>$child));
    } else {
      if ($child.children.length > 0) {
        matches.push(...queryShadowRoot($child, maxDepth, depth + 1));
      }
    }
  }

  return matches.filter((c, index) => matches.indexOf(c) === index);
};
