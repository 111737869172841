export const addSubmitHandler = (formElement: HTMLFormElement) => {
  formElement.addEventListener('submit', (event) => {
    event.preventDefault();
    const formData = new FormData(formElement);
    const path = formData.get('coveragetype') as string;
    if (formElement.hasAttribute('data-url-external')) {
      window.open(path, '_blank');
    } else {
      location.assign(path);
    }
  });
};

export const initialize = (selector = '[data-url-select]') => {
  document.querySelectorAll(selector).forEach((element) => {
    addSubmitHandler(element as HTMLFormElement);
  });
};
