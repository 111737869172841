const isSpanish = window.location.pathname.includes('es-us');

export const isExternalLink = (href: string) => {
  try {
    const url = new URL(href);
    return url.protocol.startsWith('http') && url.origin !== location.origin;
  } catch (_error) {
    return false;
  }
};

export const updateLink = (link: HTMLAnchorElement) => {
  const isAction = link.classList.contains('leaf-u-link--action');
  const isExternal = isExternalLink(link.href);
  const linkText = link.textContent || '';

  if (link.href && isExternal && !isAction) {
    if (!link.hasAttribute('data-no-icon')) {
      link.classList.add('leaf-u-link--external');
    }
    link.setAttribute('rel', 'noopener');
    link.setAttribute('target', '_blank');
    if (!link.hasAttribute('aria-label')) {
      const label = isSpanish ? 'Sitio externo' : 'External Site';
      link.setAttribute('aria-label', `${linkText} ${label}`);
    }
  }
};

export const fixExternalLinks = () => {
  document.querySelectorAll('a').forEach((link) => {
    updateLink(link);
  });
};

export const stripHtmlFromLink = (url: string): string => {
  // Remove 'index.html' if it is at the end of the URL
  url = url.replace(/index\.html$/, '');

  // Remove '.html' if it is at the end of the URL
  url = url.replace(/\.html$/, '');

  return url;
};
