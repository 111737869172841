import { ReactiveController, ReactiveControllerHost } from 'lit';
import type { i18n } from 'i18next';
import type { I18nMixinInterface } from './i18n-mixin.js';

export class I18nController implements ReactiveController {
  t: i18n['t'];
  error?: { lng: string; ns: string; msg: string };
  loading = true;

  get language() {
    return this.i18nextInstance.language;
  }

  set language(value: string) {
    this.i18nextInstance.changeLanguage(value);
  }

  constructor(
    private host: ReactiveControllerHost,
    private i18nextInstance: i18n,
    ns: string | string[],
  ) {
    this.host.addController(this);
    this.loadNamespaces(ns);
    this.t = this.i18nextInstance.t.bind(this);
  }

  loadNamespaces(ns: string | string[]) {
    this.i18nextInstance.loadNamespaces(ns).then((_) => {
      this.loading = false;
      if (Object.prototype.hasOwnProperty.call(this.host, 'onNamespacesLoaded')) {
        (this.host as ReactiveControllerHost & I18nMixinInterface).onNamespacesLoaded();
      }
      this.host.requestUpdate();
    });
  }

  hostConnected() {
    this.i18nextInstance.on('languageChanged', this.onLanguageChanged.bind(this));
    this.i18nextInstance.on('failedLoading', this.onFailedLoading.bind(this));
  }

  hostDisconnected() {
    this.i18nextInstance.off('languageChanged', this.onLanguageChanged.bind(this));
    this.i18nextInstance.off('failedLoading', this.onFailedLoading.bind(this));
    this.host.removeController(this);
  }

  protected onLanguageChanged(_lng: string) {
    this.host.requestUpdate();
  }

  protected onFailedLoading(lng: string, ns: string, msg: string) {
    this.error = { lng, ns, msg };
    this.host.requestUpdate();
  }

  /**
   * Gets loaded data for a given namespace, useful if manipulating data is required
   *
   * @param namespace optional, namespace to load - gets all namespaces if omitted
   */
  getLanguageData(namespace?: string): unknown {
    const language = this.i18nextInstance.language;
    if (namespace) {
      return this.i18nextInstance.getResourceBundle(language, namespace);
    }
    return this.i18nextInstance.getDataByLanguage(language);
  }
}
