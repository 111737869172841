export const addSubmitHandler = (formElement: HTMLFormElement) => {
  formElement.addEventListener('submit', (event) => {
    event.preventDefault();
    const formData = new FormData(formElement);
    const hideShowSelected = formData.get('hideshowselected') as string;
    const hideShowContainer = formElement.querySelector('.hide-show-select-container');
    hideShowContainer?.querySelector('[id]:not(.leaf-u-display-none)')?.classList.add('leaf-u-display-none');
    hideShowContainer?.querySelector(`#${hideShowSelected}`)?.classList.remove('leaf-u-display-none');
  });
};

export const initialize = (selector = '[data-hide-show-select]') => {
  document.querySelectorAll(selector).forEach((element) => {
    addSubmitHandler(element as HTMLFormElement);
  });
};
